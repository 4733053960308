<template>

  <v-row class="inner-content">
    <v-col>

      <v-row>
        <v-col>
          <RegionsTable />
        </v-col>
      </v-row>

    </v-col>
  </v-row>

</template>

<script>
export default {
  name: 'RegionsList',

  components: {
    RegionsTable: () => import('@/components/tables/RegionsTable.vue')
  },
}
</script>
